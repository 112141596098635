<div class="row">
  <ng-container *ngFor="let campo of Campi">
    <div [ngClass]="campo.prevDivClass"></div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
      *ngIf="campoUtility.Show(campo, Oggetto, true, Type, CampiNascosti ?? [])">
      <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
        <ng-template
          [ngIf]=" campo.type != 'Crud' && campo.type != 'component' && campo.type != 'template' && campo.inputType != 'day-selection' && campo.inputType != 'firma' && campo.type != 'CategoriaNav' && campo.type != 'checkValutazione' && campo.type != 'PartecipantiTyped' && campo.type != 'object' && campo.inputType != 'html' && campo.inputType != 'blackhtml' &&  campo.inputType != 'checkbox' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown' &&  campo.inputType != 'textarea' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray' && campo.type!= 'table' && campo.inputType != 'file' && campo.type!= 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'Files' && campo.type != 'image' && campo.inputType != 'ripetizioneTask'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
            *ngIf=" !campo.titledivClass && !hideLabel">{{campo.nome
            | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group mb-2" style="align-items: center">
            <div [class]="campo.titledivClass" *ngIf="campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
            </div>

            <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [type]="campo.inputType"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
              [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
              class="form-control"> &nbsp;
            <div class="input-group-append">
              <ng-container *ngFor="let bottone of campo.azioni">
                <a href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, Oggetto[oggetto])"
                  [ngbTooltip]="bottone.tooltip" class="link font-16 font-weight-bold" [style.color]="bottone.color"
                  (click)='ChangeHandler(bottone.ExternalOnClick, Oggetto[oggetto], bottone, campo)'>
                  &nbsp;
                  <i [ngClass]="bottone.icon"></i>

                  &nbsp;
                  {{bottone.label}}
                  &nbsp;
                </a>
              </ng-container>
            </div>
          </div>
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && (campo.required || campoUtility.Required(campo, Oggetto)) && !Oggetto[oggetto]">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'day-selection'">
          <div class="day-selection" *ngIf="campoUtility.getObj(Oggetto, oggetto)">
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.monday"
              (click)="toggleDay(oggetto,'monday')">L</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.tuesday"
              (click)="toggleDay(oggetto, 'tuesday')">M</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.wednesday"
              (click)="toggleDay(oggetto, 'wednesday')">M</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.thursday"
              (click)="toggleDay(oggetto, 'thursday')">G</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.friday"
              (click)="toggleDay(oggetto, 'friday')">V</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.saturday"
              (click)="toggleDay(oggetto, 'saturday')">S</div>
            <div class="day-circle" [class.selected]="campoUtility.getObj(Oggetto, oggetto)?.sunday"
              (click)="toggleDay(oggetto, 'sunday')">D</div>
          </div>
          <div *ngIf="!campoUtility.getObj(Oggetto, oggetto)">
            <small class="form-text text-danger">Attenzione il campo {{oggetto | translate}} è vuoto</small>
          </div>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'html'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
            [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
            [config]="editorConfig"></angular-editor>

        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'blackhtml'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
            [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
            [config]="blackEditorConfig"></angular-editor>
        </ng-template>
        <ng-template
          [ngIf]=" campo.type != 'Crud' && campo.inputType != 'firma' && campo.type == 'object' && campo.inputType != 'checkbox' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown'  && Oggetto[oggetto] && campo.inputType != 'textarea' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray' && campo.type!= 'table' && campo.type!= 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'image'">

          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
            *ngIf=" !campo.titledivClass && !hideLabel">{{campo.nome
            | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group mb-2" style="align-items: center">



            <div [class]="campo.titledivClass" *ngIf="campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
            </div>


            <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [type]="campo.inputType"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
              [(ngModel)]="Oggetto[oggetto][campo.field[i]]" (ngModelChange)="OnChange.emit($event)"
              class="form-control">
            &nbsp;
            <ng-container *ngFor="let bottone of campo.azioni">
              <a href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, Oggetto[oggetto])"
                [ngbTooltip]="bottone.tooltip" class="link font-16 font-weight-bold" [style.color]="bottone.color"
                (click)='ChangeHandler(bottone.ExternalOnClick, Oggetto[oggetto][campo.field[i]], bottone, campo)'>
                &nbsp;
                <i [ngClass]="bottone.icon"></i>
                &nbsp;
              </a>
            </ng-container>
          </div>

          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && (campo.required || campoUtility.Required(campo, Oggetto)) && !Oggetto[oggetto][campo.field[i]]">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type != 'object' && campo.inputType == 'checkbox'">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="checkbox"
            [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || Disable(campo, Padre ?? Oggetto)"
            [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]"
            (ngModelChange)="OnChange.emit($event)" class="form-control"> &nbsp;
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto]">Attenzione il campo
            {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'object' && campo.inputType == 'checkbox' && Oggetto[oggetto] ">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="checkbox"
            [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || Disable(campo, Padre ?? Oggetto)"
            [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto][campo.field[i]]"
            (ngModelChange)="OnChange.emit($event)" class="form-control"> &nbsp;
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto][campo.field[i]]">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>

        <ng-container *ngIf="campo.type != 'object'  && campo.inputType == 'ripetizioneTask' ">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome |
            translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <app-ripetizione-task [Disabled]="Disable(campo, Padre ?? Oggetto)" [(ripetizione)]="Oggetto[oggetto]" [Padre]="Oggetto"
            [Sources]="campo.Sources"></app-ripetizione-task>
        </ng-container>
        <ng-container *ngIf="campo.type == 'object' && campo.inputType == 'ripetizioneTask' && Oggetto[oggetto] ">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome |
            translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
          <app-ripetizione-task [Disabled]="Disable(campo, Padre ?? Oggetto)" [(ripetizione)]="Oggetto[oggetto][campo.field[i]]"></app-ripetizione-task>
        </ng-container>

        <ng-template [ngIf]="campo.type != 'object' && campo.inputType == 'textarea'">
          <label *ngIf="!hideLabel && !campo.titledivClass" [class]="campo.titleClass"
            [ngStyle]="campo.titleStyle">{{campo.nome |
            translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group" style="align-items: center">
            <div [class]="campo.titledivClass ?? ''" *ngIf="!hideLabel && campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
            </div>
            <!-- <label *ngIf="!hideLabel" [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<a
                *ngIf="campo.tooltip">&nbsp;<i class="far fa-question-circle"
                  [ngbTooltip]="campo.tooltip"></i></a></label> -->
            <textarea [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity)  || Disable(campo, Padre ?? Oggetto)"
              [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="OnChange.emit($event)"
              [placeholder]="campo.placeholder ?? campo.nome | translate" class="form-control"></textarea> &nbsp;
          </div>
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto]">Attenzione il campo
            {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && campo.inputType == 'textarea'">
          <label *ngIf="!hideLabel && !campo.titledivClass" [class]="campo.titleClass"
            [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group" style="align-items: center">
            <div [class]="campo.titledivClass ?? ''" *ngIf="!hideLabel && campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
            </div>
            <!-- <label *ngIf="!hideLabel" [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}<a
                          *ngIf="campo.tooltip">&nbsp;<i class="far fa-question-circle"
                            [ngbTooltip]="campo.tooltip"></i></a></label> -->
            <textarea [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || Disable(campo, Padre ?? Oggetto)"
              [(ngModel)]="Oggetto[oggetto][campo.field[i]]" (ngModelChange)="OnChange.emit($event)"
              [placeholder]="campo.placeholder ?? campo.nome | translate" class="form-control"></textarea>
          </div>

          &nbsp;
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto][campo.field[i]]">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>


        <ng-template
          [ngIf]="campo.type != 'object' && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
            *ngIf=" !campo.titledivClass && !hideLabel">{{campo.nome
            | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group" style="align-items: center">
            <div [class]="campo.titledivClass" *ngIf="campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>
            </div>
            <ng-multiselect-dropdown
              *ngIf="campo.internalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [settings]="campo.settings"
              [placeholder]="campo.placeholder ?? 'Seleziona'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [data]="DropdownSources[campo.source] ?? campo.source" [(ngModel)]="Oggetto[campo.internalOutput]"
              (onFilterChange)="FilterHandler(campo, $event)"
              (onMoreButtonClick)="More($event,campo)"
              (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo, Oggetto)"></ng-multiselect-dropdown>
            <ng-multiselect-dropdown
              *ngIf="campo.externalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [required]="campo.required" [settings]="campo.settings"
              [placeholder]="campo.placeholder ?? 'Seleziona'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [data]="DropdownSources[campo.source] ?? campo.source" [(ngModel)]="DropdownSources[campo.externalOutput]"
              (onFilterChange)="FilterHandler(campo, $event)"
              (onMoreButtonClick)="More($event, campo, DropdownSources[campo.externalOutput])"
              (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)">
            </ng-multiselect-dropdown>
            <div class="input-group-append" *ngIf="campo.azioni?.length">
              <ng-container *ngFor="let bottone of campo.azioni">
                <a href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, Oggetto[oggetto])"
                  [ngbTooltip]="bottone.tooltip" class="link font-16 font-weight-bold" [style.color]="bottone.color"
                  (click)='ChangeHandler(bottone.ExternalOnClick, Oggetto[oggetto], bottone, campo)'>
                  &nbsp;
                  <i [ngClass]="bottone.icon"></i>
                  &nbsp;
                  {{bottone.label}}
                  &nbsp;
                </a>
              </ng-container>
            </div>
          </div>
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !DropdownSources[campo.externalOutput] && (!Oggetto[campo.internalOutput] || (!campo.settings.singleOutput && !(Oggetto[campo.internalOutput]?.length >0)))">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template
          [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
            *ngIf=" !campo.titledivClass && !hideLabel">{{campo.nome | translate}}<app-tooltip-icon [campo]="campo"></app-tooltip-icon></label>

          <div class="input-group" style="align-items: center">
            <div [class]="campo.titledivClass" *ngIf="campo.titledivClass">
              <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome |
                translate}} <app-tooltip-icon [campo]="campo"></app-tooltip-icon> </label>
            </div>
            <ng-multiselect-dropdown
              *ngIf="campo.internalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source) )"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [required]="campo.required" [settings]="campo.settings"
              [placeholder]="campo.placeholder ?? 'Seleziona'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [data]="!IsArray(campo.source) ? DropdownSources[campo.source] : campo.source"
              [(ngModel)]="Oggetto[campo.internalOutput][campo.field[i]]"
              (onFilterChange)="FilterHandler(campo, $event)"
              (onMoreButtonClick)="More($event, campo)"
              (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo, Oggetto[campo.internalOutput][campo.field[i]])"></ng-multiselect-dropdown>
            <ng-multiselect-dropdown
              *ngIf="campo.externalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
              [disabled]="Disable(campo, Padre ?? Oggetto)" [required]="campo.required" [settings]="campo.settings"
              [placeholder]="campo.placeholder ?? 'Seleziona'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
              [data]=" !IsArray(campo.source) ? DropdownSources[campo.source] : campo.source"
              [(ngModel)]="DropdownSources[campo.externalOutput][campo.field[i]]"
              (onFilterChange)="FilterHandler(campo, $event)"
              (onMoreButtonClick)="More($event, campo)"
              (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo, DropdownSources[campo.externalOutput][campo.field[i]])">
            </ng-multiselect-dropdown>
          </div>
          <small class="form-text text-danger"
            *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !DropdownSources[campo.externalOutput] && !Oggetto[campo.internalOutput]">Attenzione
            il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>


        <ng-template [ngIf]="campo.type == 'ArrayCampiAggiuntivi'">
          <app-field-viewer *ngIf="!campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
            [(Campi)]="Oggetto[oggetto]" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
          <app-field-viewer *ngIf="campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
            [(Campi)]="campo.campiAggiuntivi" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
        </ng-template>


        <ng-template [ngIf]="campo.type == 'checkboxArray' && Oggetto && Oggetto[oggetto]">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [titolo]="campo.nome" [(Oggetto)]="Oggetto"
            [HideCampiAgg]="campo['HideCampiAgg']" (OnChange)="OnChange.emit($event)"
            [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArrayMEXC'">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [titolo]="campo.nome" [(Oggetto)]="Oggetto"
            (OnChange)="OnChange.emit($event)" [MutualmenteEsclusivi]="true"
            [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>

        <ng-container *ngIf="campo.inputType == 'file'">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
                  class="input-group-text">{{campo.nome}}</span>
              </div>
              <div class="custom-file">
                <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="file"
                  [disabled]="Disable(campo, Padre ?? Oggetto)" class="custom-file-input" id="allegato"
                  (change)="filechange(campo.OnChange, $event, campo)" placeholder="">
                <label class="custom-file-label" for="allegato">{{Oggetto[(campo?.oggetti[0] ?? '') + 'file']}}</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <span>{{campo.placeholder}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="campo.inputType == 'firma'">
          <div style="padding: 20px;">
            <signature-pad #pad style="display: inline-block; outline-style: groove;
                outline-color: black;
                outline-width: 0.5px;
               " [options]="signaturePadOptions" (onEndEvent)="drawComplete(campo, pad)"></signature-pad>
            <button (click)="cancellaFirma(pad)" class=" btn btn-danger p-2 waves-effect waves-light">
              <span class="ml-1 text-white font-weight-bold"> Pulisci </span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf=" campo.type == 'image'">
          <div class="el-element-overlay">
            <div class="el-card-item">
              <div class="el-card-avatar el-overlay-1">
                <img *ngIf="Oggetto[oggetto] || Oggetto[campo.imageToServer]"
                  [src]="Oggetto[campo.imageToServer] ? Oggetto[campo.imageToServer] : GetImageID(Oggetto[oggetto])"
                  (error)="onImageError(Oggetto)" alt="user" />
                <div class="el-overlay">
                  <ul class="list-style-none el-info">
                    <li class="el-item" *ngIf="GetImageID(Oggetto[oggetto])">
                      <a class="btn default btn-outline image-popup-vertical-fit el-link"
                        [href]="GetImageID(Oggetto[oggetto])">
                        <i class="icon-magnifier"></i>
                      </a>
                    </li>
                    <li class="el-item">
                      <a class="btn default btn-outline el-link" href="javascript:void(0);">
                        <i class="icon-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-info waves-effect waves-light">
            <span>Upload Anonther Image</span>
            <input type="file" class="upload"
              (change)="ImageChange($event, campo.imageToServer, 'image', campo.filename)">
          </div>
        </ng-container>

        <ng-container *ngIf="campo.type == 'checkValutazione'">
          <app-check-list-valutazione [(Selected)]="Oggetto[oggetto]" [isModifyDisabled]="Disable(campo, this.Oggetto ?? this.Padre)"
            [valutazione]="Oggetto"></app-check-list-valutazione>
        </ng-container>

        <ng-template [ngIf]="campo.type == 'table'">
          <div class="d-md-flex align-items-center  py-2" [class]="campo.titleClass" [ngStyle]="campo.titleStyle"
            *ngIf="campo.nome || campo.AddClick || campo.ExternalAddClick || campo?.AzioniTask?.length > 0">
            <span class="d-flex align-items-center  " *ngIf="campo.nome">
              <span class="font-weight-bold font-14" style="margin-left: 10px">{{campo.nome}} &nbsp;&nbsp;&nbsp;</span>
              <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Oggetto[oggetto]?.length ?? 0}}</span>
                &nbsp;
                &nbsp;
                &nbsp;
              <a href="javascript:void(0)" class="ml-auto" *ngIf="campo.collapsable"
                (click)="campo.collapsed = !campo.collapsed"><i class="fas fa-chevron-down text-dark"
                  style="transition: all 0.3s ease-out; transform-origin: top;"
                  [style.transform]="!campo.collapsed? 'rotate(180deg) translate(0, -50%)' : ''"></i></a>

            </span>
            <div class="ml-auto mt-3 mt-md-0" *ngIf="campo.AddClick || campo.ExternalAddClick">
              <button type="button" (click)="AddClick(campo)" class="btn btn-cyan rounded-pill p-2"><i
                  class="fas fa-plus  font-14 mr-1 text-white"></i>
                <span class="ml-1 text-white font-weight-bold">{{'Fields.add' | translate}}</span>
              </button>
            </div>
            <div class="ml-auto mt-3 mt-md-0" *ngIf="campo?.AzioniTask?.length > 0 && !Disable(campo, this.Oggetto ?? this.Padre)" >
              <ng-container *ngFor="let button of campo['AzioniTask']">
                <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
                  *ngIf=" (!button.hideOnSubtab) && permission.HasCapacity(button['capacita']) && campoUtility.ButtonShow(button,Oggetto)"
                  (click)='customButton(Oggetto,button)'>
                  <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
                  <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
                </button>
              </ng-container>
            </div>
          </div>
          <div class="table-responsive py-3" *ngIf="campo.collapsed || !campo.collapsable">
            <table class="table bg-white table-hover align-middle mb-0 ">
              <thead>
                <tr>
                  <ng-container *ngFor="let headfield of campo.header">
                    <th scope="col"
                      *ngIf="campoUtility.Show(campo, Oggetto, true, Type) && headfield.inputType != 'checkbox'">
                      {{headfield.nome}}
                      <app-tooltip-icon [campo]="headfield"></app-tooltip-icon>
                    </th>
                    <th scope="col"
                      *ngIf="campoUtility.Show(campo, Oggetto, true, Type) && headfield.inputType == 'checkbox'">
                      <a href="javascript:void(0)" class="link"
                        (click)="toggleAll(headfield, Oggetto[oggetto])">{{allSelected(headfield, Oggetto[oggetto]) ?
                        'Deseleziona tutto' : 'Seleziona tutto'}}</a>
                    </th>
                  </ng-container>
                  <th *ngIf="campo.azioni && hasAzioni(campo.azioni, Oggetto[oggetto])">{{'Azioni' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of Oggetto[oggetto]; let i = index">
                  <ng-container *ngFor="let headfield of campo.header">
                    <td *ngIf="campoUtility.Show(campo, Oggetto, true, Type)">
                      <ng-container *ngFor="let fiel of headfield.oggetti; let k = index">
                        <ng-template [ngIf]="headfield.type == 'replace' ">
                          {{campoUtility.Replace(headfield, Oggetto[oggetto][i], fiel)}} &nbsp;
                        </ng-template>
                        <ng-template [ngIf]="headfield.type == 'objArray' ">
                          <ng-container *ngFor="let obg of campoUtility.getObj(Oggetto[oggetto][i], fiel)">
                            {{campoUtility.getObj(obg, headfield.field[k])}}&nbsp;
                          </ng-container>
                        </ng-template>
                      </ng-container>
                      
                      <app-field-viewer *ngIf="headfield.type != 'replace' && headfield.type != 'objArray'" [Campi]="[headfield]" [hideLabel]="true" [(Type)]="Type"
                        [isModifyDisabled]="Disable(campo, this.Oggetto ?? this.Padre)"
                        [(Oggetto)]="Oggetto[oggetto][i]"></app-field-viewer>
                    </td>
                  </ng-container>
                  <td *ngIf="campo.azioni && hasAzioni(campo.azioni, Oggetto[oggetto])">
                    &nbsp;
                    <ng-container *ngFor="let bottone of campo.azioni">
                      <a href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, field, Oggetto)"
                        [ngbTooltip]="bottone.tooltip" class="link font-16 font-weight-bold"
                        [style.color]="bottone.color"
                        (click)='ChangeHandler(bottone.ExternalOnClick, field, bottone, campo)'>
                        <i class="fas" [ngClass]="bottone.icon"></i>
                        &nbsp;
                      </a>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'Partecipanti' && Oggetto && Oggetto[oggetto]">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome"
            type="file" [(List)]="Oggetto[oggetto]" [hasAddButton]="true" [Padre]="Oggetto"></app-partecipanti-list>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'PartecipantiTyped' && Oggetto && Oggetto[oggetto]">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome"
            type="file" [Types]="campo.source" [Typed]="true" [(List)]="Oggetto[oggetto]" [hasAddButton]="true"
            [Padre]="Oggetto"></app-partecipanti-list>
        </ng-template>

        <ng-template
          [ngIf]="campo.type == 'CategoriaNav' && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))">
          <app-categorie-nav-select [nameCategoria1]="'Categoria'" [nameCategoria2]="campo.nome"
            [nameCategoria3]="campo.nome" [(cat_id)]="Oggetto[oggetto]" [disable]="Disable(campo, Padre ?? Oggetto)"
            [categorie]="DropdownSources[campo.source] ?? campo.source"></app-categorie-nav-select>
        </ng-template>
        <small class="form-text text-warning"
          *ngIf="!isModifyDisabled && campoUtility.Warning(campo,Oggetto)">{{campoUtility.WarningText(campo,Oggetto ) |
          translate}}</small>
      </ng-container>
      <ng-container *ngIf="campo.type == 'Crud'">
        <app-dynamic-list [CrudId]="campo.inputType" [title]="campo.nome ?? ''"
          [filtro]="CreateParams(campo.filtro, Oggetto)"
          [ConcatParams]="CreateParams(campo.CrudSetting['ConcatParams'] ?? {}, Oggetto)"></app-dynamic-list>
      </ng-container>

    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
      *ngIf=" campoUtility.Show(campo, Oggetto, false, Type, CampiNascosti ?? [])">
      <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
        <ng-template
          [ngIf]="campo.inputType != 'checkbox' && campo.type != 'component' && campo.type != 'template' && campo.inputType != 'html' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray'&& campo.type != 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'Files'">
          <label *ngIf="i == 0 && !hideLabel" [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome |
            translate}}</label>
          <span *ngIf="campo.inputType != 'date' && campo.inputType != 'datetime-local' && campo.inputType != 'euro'"
            [class]="campo.inputClass" [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i)
            }}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'date'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle">{{campo.prefix
            ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy'}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'datetime-local'" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy HH:mm'}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'euro'" [class]="campo.inputClass" [ngStyle]="campo.inputStyle">{{campo.prefix
            ?? ''}}{{getObject(campo, i) | number: '2.2-2'}}&nbsp;€{{campo.suffix ?? ''}}</span>
          <ng-template [ngIf]="campo.type == 'replace' ">
            {{campoUtility.Replace(campo,Oggetto, oggetto)}} &nbsp;
          </ng-template>
          <button type="submit" style="margin-left: 5px" *ngIf="campo.OnChange?.length > 0"
            (click)="ChangeHandler(campo.OnChange, $event, campo)" class=" btn btn-info p-2 ">
            <span class="ml-1 text-white font-weight-bold"> Aggiorna </span>
          </button>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'template' && campo.func ">
          <ng-container *ngFor="let template of campo.func(Oggetto, this)">
            <ng-container *ngTemplateOutlet="template"></ng-container>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'component' && campo.func ">
          <ng-container *ngFor="let component of campo.func(Oggetto, this)">
            <ng-container *ngComponentOutlet="outletConfig(component)"  ></ng-container>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'html' && campo.func ">
          <div [innerHTML]="campo.func(Oggetto, this)"></div>
        </ng-template>


        
        <ng-template [ngIf]="campo.inputType == 'html'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
            [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
            [config]="readonlyEditorConfig"></angular-editor>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'blackhtml'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [disabled]="Disable(campo, Padre ?? Oggetto)" [placeholder]="campo.placeholder ?? campo.nome | translate"
            [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
            [config]="readonlyEditorConfig"></angular-editor>
        </ng-template>
        <ng-template
          [ngIf]="campo.type != 'object' && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <span *ngIf="campo.internalOutput && IsArray(Oggetto[campo.internalOutput])" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">
            <ng-template *ngFor="let val of Oggetto[campo.internalOutput]">
              {{campo.prefix ?? ''}}{{val[campo.settings['textField']] ?? val['label']}}{{campo.suffix ?? ''}}
            </ng-template>

          </span>
          <span *ngIf="campo.internalOutput && Oggetto[campo.internalOutput] && !IsArray(Oggetto[campo.internalOutput])"
            [class]="campo.inputClass" [ngStyle]="campo.inputStyle">{{campo.prefix ??
            ''}}{{Oggetto[campo.internalOutput][campo.settings['textField']] ??
            Oggetto[campo.internalOutput]['label']}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.externalOutput &&  DropdownSources[campo.externalOutput]" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ??
            ''}}{{DropdownSources[campo.externalOutput][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
        </ng-template>
        <ng-template
          [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <span *ngIf="campo.externalOutput && Oggetto[campo.externalOutput]" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ??
            ''}}{{Oggetto[campo.externalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.internalOutput && Oggetto[campo.internalOutput]" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ??
            ''}}{{Oggetto[campo.internalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'checkbox'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf=" !hideLabel">{{campo.nome |
            translate}}</label>
          <span [class.text-danger]="!getObject(campo,i)" [class.text-success]="getObject(campo,i)">
            {{campo.prefix ?? ''}}
            <i [class.far]="!getObject(campo,i)" [class.fa-times-circle]="!getObject(campo,i)"
              [class.fas]="getObject(campo,i)" [class.fa-check-circle]="getObject(campo,i)"></i>
            {{campo.suffix ?? ''}}
          </span>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'ArrayCampiAggiuntivi'">
          <app-field-viewer *ngIf="!campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
            [(Campi)]="Oggetto[oggetto]" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
          <app-field-viewer *ngIf="campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
            [(Campi)]="campo.campiAggiuntivi" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArrayMEXC'">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)"
            [MutualmenteEsclusivi]="true" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArray'">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)"
            [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'Partecipanti'">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome"
            type="file" [(List)]="Oggetto[oggetto]" [hasAddButton]="false" [ModificheAbilitate]="false"
            [Padre]="Oggetto"></app-partecipanti-list>
        </ng-template>
      </ng-container>
    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
      *ngIf="campo.type == 'Files' && Oggetto?.id && !(campo?.oggetti?.length>0) && campoUtility.Show(campo, Oggetto, true, Type)">
      <app-file-uploader [id]="Oggetto.id" [(listafile)]="Oggetto.files" [url]="campo.url" [name]="campo.nome"
        (onChange)="ChangeHandler(campo.OnChange, $event, campo)"
        (onFileSaved)="ChangeHandler(campo.OnChange, $event, campo)" [DropdownSources]="Oggetto"
        [GestioneFile]="campo.GestioneFile ?? ((!Disable(campo, Padre ?? Oggetto) ?? true) || Oggetto['bloccato'])  "
        [campiAggiuntivi]="campo.campiAggiuntivi" [attr]="campo.attr"
        (allowedFilesChange)="ChangeHandler('allowedFiles', $event, campo)"
        (listafileChange)="ChangeHandler('listafileChange', $event, campo)"></app-file-uploader>
    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
      *ngIf="campo.type == 'Files' && Oggetto?.id && campo?.oggetti?.length > 0 && Oggetto[campo.oggetti[0]] && (campo.visibleifProhibited || (permission.isOperativeRoles(campo.AllowedRoles) && permission.isntOperativeRoles(campo.prohibitedRoles) && permission.HasCapacity(campo.allowedCapacity)))">
      <app-file-uploader [id]="Oggetto.id" [(listafile)]="Oggetto[campo.oggetti[0]].files" [url]="campo.url"
        [name]="campo.nome" (onChange)="ChangeHandler(campo.OnChange, $event, campo)"
        (onFileSaved)="ChangeHandler(campo.OnChange, $event, campo)" [DropdownSources]="Oggetto"
        [GestioneFile]="campo.GestioneFile ?? !Disable(campo, Padre ?? Oggetto) ?? true "
        [campiAggiuntivi]="campo.campiAggiuntivi" [attr]="campo.attr"
        (allowedFilesChange)="ChangeHandler('allowedFiles', $event, campo)"
        (listafileChange)="ChangeHandler('listafileChange', $event, campo)"></app-file-uploader>
    </div>

    <div [ngClass]="campo.nextDivClass"></div>
  </ng-container>
</div>



<ng-template #dynamicContainer></ng-template>
